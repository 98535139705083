/* eslint-disable */
/* tslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDebtorMutationVariables = Exact<{
  payload: CreateDebtorInput;
}>;

export type CreateDebtorMutation = { createDebtor: { id: string } };

export type CompanySearchQueryVariables = Exact<{
  search: SearchInput;
}>;

export type CompanySearchQuery = {
  companySearch: Array<{
    companySearchId: string;
    name: string;
    address: string;
    registrationNumber: string;
    tradingNames: Array<string>;
  }>;
};

export type DebtorQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type DebtorQuery = {
  debtor: {
    id: string;
    created: string;
    company: {
      officialName?: string | null;
      registrationNumber?: string | null;
      branchSet: Array<{
        accountsPayableEmails?: Array<string> | null;
        invoicingEmails?: Array<string> | null;
        name: string;
        isMainBranch: boolean;
        enabled: boolean;
        branchNumber: string;
        accountName?: string | null;
        accountNumber?: string | null;
        bankAccountCode?: string | null;
      }>;
    };
  };
};

export type BranchListQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>;
  ordering?: InputMaybe<BranchesOrderByChoicesQueryOrdering>;
  filters?: InputMaybe<BranchFiltersInput>;
}>;

export type BranchListQuery = {
  branches: {
    items: Array<{
      id: string;
      name: string;
      accountName?: string | null;
      accountNumber?: string | null;
      accountsPayableEmails?: Array<string> | null;
      bankAccountCode?: string | null;
      branchNumber: string;
      contactEmail?: string | null;
      contactMobile?: string | null;
      contactName?: string | null;
      contactPhone?: string | null;
      contractPdf?: string | null;
      currency?: Currency | null;
      defaultDepotPercentage?: string | null;
      defaultGsplitPercentage?: string | null;
      enabled?: boolean | null;
      invoicingEmails?: Array<string> | null;
      isMainBranch: boolean;
      paymentTerm?: number | null;
      status?: ContractStatus | null;
      company: {
        legalForm?: string | null;
        registrationNumber?: string | null;
        vatNumber?: string | null;
        contactInfo: { phone: string; website: string };
      };
      creditLimit: { creditLimit: string; creditUsed: string; creditAvailable: string };
    }>;
    pageInfo: { totalCount: number; hasNextPage?: boolean | null; hasPrevPage?: boolean | null };
  };
};

export type GetBranchQueryVariables = Exact<{
  branchId: Scalars['UUID']['input'];
}>;

export type GetBranchQuery = {
  branch: {
    id: string;
    name: string;
    accountName?: string | null;
    accountNumber?: string | null;
    accountsPayableEmails?: Array<string> | null;
    bankAccountCode?: string | null;
    branchNumber: string;
    contactEmail?: string | null;
    contactMobile?: string | null;
    contactName?: string | null;
    contactPhone?: string | null;
    contractPdf?: string | null;
    defaultDepotPercentage?: string | null;
    defaultGsplitPercentage?: string | null;
    enabled?: boolean | null;
    invoicingEmails?: Array<string> | null;
    isMainBranch: boolean;
    paymentTerm?: number | null;
    status?: ContractStatus | null;
    company: {
      legalForm?: string | null;
      registrationNumber?: string | null;
      vatNumber?: string | null;
      contactInfo: { phone: string; website: string };
    };
    creditLimit: { creditLimit: string; creditUsed: string; creditAvailable: string };
    billingAddress?: {
      street: string;
      houseNumber: string;
      city: string;
      postalCode: string;
      countryCode: string;
      floor: string;
      phone: string;
    } | null;
  };
};

export type RetrieveApiKeyMutationVariables = Exact<{ [key: string]: never }>;

export type RetrieveApiKeyMutation = { retrieveApiKey: { apiKey: string } };

export type SettlementsQueryVariables = Exact<{
  filters?: InputMaybe<SettlementFilters>;
  pagination?: InputMaybe<Pagination>;
  ordering?: InputMaybe<SettlementOrderByChoicesQueryOrdering>;
}>;

export type SettlementsQuery = {
  settlements: {
    items: Array<{
      uuid: string;
      amount: string;
      currency: Currency;
      created: string;
      description: string;
      invoiceUuid?: string | null;
      settledWithId?: string | null;
      transactionStatementId?: string | null;
      settlementType: SettlementType;
      status: SettlementStatus;
      invoice?: { uuid: string; identifier: string } | null;
      settledWith?: { id: string; officialName: string } | null;
      transactionStatement?: { uuid: string; identifier: string; transactionDate: string } | null;
    }>;
    pageInfo: { hasNextPage?: boolean | null; hasPrevPage?: boolean | null; totalCount: number };
  };
};

export type CreateMerchantUserMutationVariables = Exact<{
  payload: MerchantUserInput;
}>;

export type CreateMerchantUserMutation = { createMerchantUser: { id: string } };

export type MerchantUsersQueryVariables = Exact<{
  pagination: Pagination;
}>;

export type MerchantUsersQuery = {
  merchantUsers: {
    items: Array<{
      id: string;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      mobile?: string | null;
      phone?: string | null;
      enabled?: boolean | null;
    }>;
    pageInfo: { totalCount: number; hasNextPage?: boolean | null; hasPrevPage?: boolean | null };
  };
};

export type MerchantUserQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type MerchantUserQuery = {
  merchantUser: {
    id: string;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    mobile?: string | null;
    phone?: string | null;
    salutation?: SalutationTypeChoices | null;
    enabled?: boolean | null;
    created?: string | null;
  };
};

export type UploadInvoiceMutationVariables = Exact<{
  debtorBranchId: Scalars['UUID']['input'];
  amount: Scalars['Decimal']['input'];
  amountWithVat: Scalars['Decimal']['input'];
  directPaymentAmount: Scalars['Decimal']['input'];
  attachments: Array<Scalars['Upload']['input']> | Scalars['Upload']['input'];
  pdf: Scalars['Upload']['input'];
  poNumber: Scalars['String']['input'];
  issueDate: Scalars['Date']['input'];
  identifier: Scalars['String']['input'];
  reference?: InputMaybe<Scalars['String']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
}>;

export type UploadInvoiceMutation = { uploadInvoice: { uuid: string } };

export type UpdateInvoiceMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  debtorBranchId: Scalars['UUID']['input'];
  amount: Scalars['Decimal']['input'];
  amountWithVat: Scalars['Decimal']['input'];
  directPaymentAmount: Scalars['Decimal']['input'];
  attachments?: InputMaybe<Array<Scalars['Upload']['input']> | Scalars['Upload']['input']>;
  pdf?: InputMaybe<Scalars['Upload']['input']>;
  poNumber: Scalars['String']['input'];
  issueDate: Scalars['Date']['input'];
  identifier: Scalars['String']['input'];
  reference?: InputMaybe<Scalars['String']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateInvoiceMutation = { updateInvoice: { uuid: string } };

export type SubmitInvoiceMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type SubmitInvoiceMutation = { submitInvoice: { success: boolean } };

export type ReopenDisapprovedInvoiceMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type ReopenDisapprovedInvoiceMutation = { reopenDisapprovedInvoice: { success: boolean } };

export type DeleteInvoiceMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteInvoiceMutation = { deleteInvoice: { success: boolean } };

export type CreateInvoiceMutationVariables = Exact<{
  data: CreateInvoiceInput;
}>;

export type CreateInvoiceMutation = { createInvoice: { uuid: string } };

export type UpdateCreatedInvoiceMutationVariables = Exact<{
  data: UpdateCreatedInvoiceInput;
}>;

export type UpdateCreatedInvoiceMutation = { updateCreatedInvoice: { uuid: string } };

export type BranchesAutocompleteListQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>;
  ordering?: InputMaybe<BranchesOrderByChoicesQueryOrdering>;
  filters?: InputMaybe<BranchFiltersInput>;
}>;

export type BranchesAutocompleteListQuery = { branches: { items: Array<{ id: string; name: string }> } };

export type BranchForInvoiceEditQueryVariables = Exact<{
  branchId: Scalars['UUID']['input'];
}>;

export type BranchForInvoiceEditQuery = {
  branch: {
    currency?: Currency | null;
    defaultDepotPercentage?: string | null;
    defaultGsplitPercentage?: string | null;
    id: string;
    name: string;
    paymentTerm?: number | null;
  };
};

export type BranchForInvoiceFilterQueryVariables = Exact<{
  branchId: Scalars['UUID']['input'];
}>;

export type BranchForInvoiceFilterQuery = { branch: { name: string } };

export type InvoicesQueryVariables = Exact<{
  filters: InvoiceFilters;
  pagination?: InputMaybe<Pagination>;
  ordering?: InputMaybe<InvoicesOrderByChoicesQueryOrdering>;
}>;

export type InvoicesQuery = {
  invoices: {
    items: Array<{
      amountWithVat: string;
      created: string;
      currency: Currency;
      daysOpen: number;
      invoiceNumber?: string | null;
      invoiceType: InvoiceType;
      issueDate?: string | null;
      issuedTo?: string | null;
      issuedToBranchId?: string | null;
      issuedToCompanyId?: string | null;
      isUploaded: boolean;
      paid?: string | null;
      paidIn?: string | null;
      paymentTerm: number;
      pdf?: string | null;
      status: InvoiceStatus;
      transactionStatement?: string | null;
      transactionStatementUuid?: string | null;
      uuid: string;
      receivableTotal: string;
    }>;
    pageInfo: { hasPrevPage?: boolean | null; hasNextPage?: boolean | null; totalCount: number };
  };
};

export type InvoiceQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type InvoiceQuery = {
  invoice: {
    amount: string;
    amountWithVat: string;
    attachments?: Array<string> | null;
    created: string;
    currency: Currency;
    daysOpen: number;
    directPayment: string;
    disapprovalReason?: string | null;
    factorFee: string;
    gsplitPercentage: string;
    gPart: string;
    invoiceNumber?: string | null;
    invoiceType: InvoiceType;
    issueDate?: string | null;
    issuedByCompanyId?: string | null;
    issuedTo?: string | null;
    issuedToBranchId?: string | null;
    issuedToCompanyId?: string | null;
    issuedBy?: string | null;
    isUploaded: boolean;
    paid?: string | null;
    paidIn?: string | null;
    paymentTerm: number;
    pdf?: string | null;
    poNumber?: string | null;
    projectId?: string | null;
    projectName?: string | null;
    projectPoNumber?: string | null;
    reference?: string | null;
    status: InvoiceStatus;
    transactionStatement?: string | null;
    transactionStatementUuid?: string | null;
    transferStatement?: string | null;
    transferStatementUuid?: string | null;
    updated: string;
    uuid: string;
    receivableTotal: string;
    outstandingTotal?: string | null;
    settlementSet?: Array<{
      amount: string;
      created: string;
      settledWithId?: string | null;
      settlementType: SettlementType;
    }> | null;
    unitLineItems?: Array<{
      uuid: string;
      vat: string;
      amount: string;
      amountWithVat: string;
      quantity: string;
      tariff: string;
      description: string;
    } | null> | null;
  };
};

export type MerchantQueryVariables = Exact<{ [key: string]: never }>;

export type MerchantQuery = {
  merchant: {
    id: string;
    company: { id: string; officialName?: string | null };
    contract: { currency: Currency; branch: { billingAddress?: { countryCode: string } | null } };
  };
};

export type InvoicesAutocompleteListQueryVariables = Exact<{
  filters: InvoiceFilters;
}>;

export type InvoicesAutocompleteListQuery = {
  invoices: { items: Array<{ uuid: string; invoiceNumber?: string | null }> };
};

export type InvoiceForFiltersQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type InvoiceForFiltersQuery = { invoice: { uuid: string; invoiceNumber?: string | null } };

export type UpdateUserLocaleMutationVariables = Exact<{
  locale: Scalars['String']['input'];
}>;

export type UpdateUserLocaleMutation = { updateUserLocale: { success: boolean } };

export type GetCompanyDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyDetailsQuery = {
  merchant: {
    id: string;
    enabled: boolean;
    created: string;
    updated: string;
    logo?: string | null;
    company: {
      id: string;
      officialName?: string | null;
      tradeName?: string | null;
      registrationNumber?: string | null;
      vatNumber?: string | null;
      legalForm?: string | null;
    };
    contract: {
      accountNumber: string;
      accountName: string;
      accountsPayableEmails: Array<string>;
      accountsReceivableEmails: Array<string>;
      bankAccountCode: string;
      contactName: string;
      contactPhone: string;
      contactMobile: string;
      contactEmail: string;
      defaultGsplitPercentage?: string | null;
      defaultFactorFees?: string | null;
      enabled: boolean;
      gAccountNumber: string;
      id: string;
      invoicingEmails?: Array<string> | null;
      signedDate?: string | null;
      status: ContractStatus;
      ublToken: string;
      agreements?: Array<{
        id?: string | null;
        agreementType?: string | null;
        agreementUrl?: string | null;
        signedDate?: string | null;
      }> | null;
      branch: {
        id: string;
        name?: string | null;
        branchNumber?: string | null;
        isMainBranch?: boolean | null;
        billingAddress?: {
          city: string;
          countryCode: string;
          floor: string;
          houseNumber: string;
          phone: string;
          postalCode: string;
          street: string;
        } | null;
      };
    };
  };
};

export type GetMerchantCompanyNameQueryVariables = Exact<{ [key: string]: never }>;

export type GetMerchantCompanyNameQuery = { merchant: { company: { officialName?: string | null } } };

export type StatementFragment = {
  uuid: string;
  identifier?: string | null;
  created: string;
  issueDate?: string | null;
  dueDate?: string | null;
  invoiceType: CollectiveInvoiceType;
  amount: string;
  amountWithVat: string;
  paid?: string | null;
  paidIn?: string | null;
  paymentTerm: number;
  pdf?: string | null;
  status: InvoiceStatus;
  directPaymentTotal: string;
  currency: Currency;
};

export type StatementsQueryVariables = Exact<{
  input: StatementsArgs;
}>;

export type StatementsQuery = {
  statements: {
    data: Array<{
      uuid: string;
      identifier?: string | null;
      created: string;
      issueDate?: string | null;
      dueDate?: string | null;
      invoiceType: CollectiveInvoiceType;
      amount: string;
      amountWithVat: string;
      paid?: string | null;
      paidIn?: string | null;
      paymentTerm: number;
      pdf?: string | null;
      status: InvoiceStatus;
      directPaymentTotal: string;
      currency: Currency;
    }>;
    pageInfo: { hasPrevPage?: boolean | null; hasNextPage?: boolean | null; totalCount: number };
  };
};

export type StatementQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type StatementQuery = {
  statement: {
    uuid: string;
    identifier?: string | null;
    created: string;
    issueDate?: string | null;
    dueDate?: string | null;
    invoiceType: CollectiveInvoiceType;
    amount: string;
    amountWithVat: string;
    paid?: string | null;
    paidIn?: string | null;
    paymentTerm: number;
    pdf?: string | null;
    status: InvoiceStatus;
    directPaymentTotal: string;
    currency: Currency;
  };
};

export type TransactionStatementsQueryVariables = Exact<{
  payload: TransactionStatementsArgs;
}>;

export type TransactionStatementsQuery = {
  transactionStatements: {
    items: Array<{
      uuid: string;
      amount: string;
      collectiveInvoiceId?: string | null;
      identifier?: string | null;
      pdf?: string | null;
      transactionDate?: string | null;
      status: TransactionStatementStatus;
      created: string;
      updated: string;
      collectiveInvoice: { currency: Currency };
    }>;
    pageInfo: { hasPrevPage?: boolean | null; hasNextPage?: boolean | null; totalCount: number };
  };
};

export type TransactionStatementQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type TransactionStatementQuery = {
  transactionStatement: {
    uuid: string;
    amount: string;
    collectiveInvoiceId?: string | null;
    identifier?: string | null;
    pdf?: string | null;
    transactionDate?: string | null;
    status: TransactionStatementStatus;
    created: string;
    updated: string;
    settlementSet: Array<{
      uuid: string;
      amount: string;
      description: string;
      created: string;
      currency: Currency;
      settlementType: SettlementType;
      status: SettlementStatus;
      invoice?: { uuid: string; identifier: string } | null;
    }>;
    collectiveInvoice: { currency: Currency };
  };
};

export type TransferStatementsQueryVariables = Exact<{
  payload: TransferStatementsArgs;
}>;

export type TransferStatementsQuery = {
  transferStatements: {
    items: Array<{
      uuid: string;
      companyId?: string | null;
      collectiveInvoiceId?: string | null;
      identifier: string;
      pdf?: string | null;
      created: string;
      updated: string;
      sentOn?: string | null;
      collectiveInvoice?: { currency: Currency } | null;
    }>;
    pageInfo: { hasPrevPage?: boolean | null; hasNextPage?: boolean | null; totalCount: number };
  };
};

export type TransferStatementQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type TransferStatementQuery = {
  transferStatement: {
    uuid: string;
    companyId?: string | null;
    collectiveInvoiceId?: string | null;
    identifier: string;
    pdf?: string | null;
    created: string;
    updated: string;
    sentOn?: string | null;
    collectiveInvoice?: { currency: Currency } | null;
  };
};

export type VatRatesQueryVariables = Exact<{
  filters?: InputMaybe<VatRateFilters>;
}>;

export type VatRatesQuery = {
  vatRates: Array<{ country: Country; level: VatRateLevel; rate: string; startDate: string }>;
};

export type FinqleMerchantContractOverviewFragment = {
  accountNumber: string;
  accountName: string;
  accountsPayableEmails: Array<string>;
  accountsReceivableEmails: Array<string>;
  bankAccountCode: string;
  contactName: string;
  contactPhone: string;
  contactMobile: string;
  contactEmail: string;
  defaultGsplitPercentage?: string | null;
  defaultFactorFees?: string | null;
  enabled: boolean;
  gAccountNumber: string;
  id: string;
  invoicingEmails?: Array<string> | null;
  signedDate?: string | null;
  status: ContractStatus;
  ublToken: string;
  agreements?: Array<{
    id?: string | null;
    agreementType?: string | null;
    agreementUrl?: string | null;
    signedDate?: string | null;
  }> | null;
  branch: {
    id: string;
    name?: string | null;
    branchNumber?: string | null;
    isMainBranch?: boolean | null;
    billingAddress?: {
      city: string;
      countryCode: string;
      floor: string;
      houseNumber: string;
      phone: string;
      postalCode: string;
      street: string;
    } | null;
  };
};

export type SettlementFragmentFragment = {
  uuid: string;
  amount: string;
  currency: Currency;
  created: string;
  description: string;
  invoiceUuid?: string | null;
  settledWithId?: string | null;
  transactionStatementId?: string | null;
  settlementType: SettlementType;
  status: SettlementStatus;
  invoice?: { uuid: string; identifier: string } | null;
  settledWith?: { id: string; officialName: string } | null;
  transactionStatement?: { uuid: string; identifier: string; transactionDate: string } | null;
};

export type CreditLimitFragmentFragment = { creditLimit: string; creditUsed: string; creditAvailable: string };

export type InvoiceFragmentFragment = {
  amount: string;
  amountWithVat: string;
  attachments?: Array<string> | null;
  created: string;
  currency: Currency;
  daysOpen: number;
  directPayment: string;
  disapprovalReason?: string | null;
  factorFee: string;
  gsplitPercentage: string;
  gPart: string;
  invoiceNumber?: string | null;
  invoiceType: InvoiceType;
  issueDate?: string | null;
  issuedByCompanyId?: string | null;
  issuedTo?: string | null;
  issuedToBranchId?: string | null;
  issuedToCompanyId?: string | null;
  issuedBy?: string | null;
  isUploaded: boolean;
  paid?: string | null;
  paidIn?: string | null;
  paymentTerm: number;
  pdf?: string | null;
  poNumber?: string | null;
  projectId?: string | null;
  projectName?: string | null;
  projectPoNumber?: string | null;
  reference?: string | null;
  status: InvoiceStatus;
  transactionStatement?: string | null;
  transactionStatementUuid?: string | null;
  transferStatement?: string | null;
  transferStatementUuid?: string | null;
  updated: string;
  uuid: string;
  receivableTotal: string;
  outstandingTotal?: string | null;
  settlementSet?: Array<{
    amount: string;
    created: string;
    settledWithId?: string | null;
    settlementType: SettlementType;
  }> | null;
  unitLineItems?: Array<{
    uuid: string;
    vat: string;
    amount: string;
    amountWithVat: string;
    quantity: string;
    tariff: string;
    description: string;
  } | null> | null;
};

export type InvoicesOverviewFragmentFragment = {
  amountWithVat: string;
  created: string;
  currency: Currency;
  daysOpen: number;
  invoiceNumber?: string | null;
  invoiceType: InvoiceType;
  issueDate?: string | null;
  issuedTo?: string | null;
  issuedToBranchId?: string | null;
  issuedToCompanyId?: string | null;
  isUploaded: boolean;
  paid?: string | null;
  paidIn?: string | null;
  paymentTerm: number;
  pdf?: string | null;
  status: InvoiceStatus;
  transactionStatement?: string | null;
  transactionStatementUuid?: string | null;
  uuid: string;
  receivableTotal: string;
};

export type VatRateFragmentFragment = { country: Country; level: VatRateLevel; rate: string; startDate: string };

export type TransferStatementFragmentFragment = {
  uuid: string;
  companyId?: string | null;
  collectiveInvoiceId?: string | null;
  identifier: string;
  pdf?: string | null;
  created: string;
  updated: string;
  sentOn?: string | null;
  collectiveInvoice?: { currency: Currency } | null;
};

export type TransactionStatementBaseFragmentFragment = {
  uuid: string;
  amount: string;
  collectiveInvoiceId?: string | null;
  identifier?: string | null;
  pdf?: string | null;
  transactionDate?: string | null;
  status: TransactionStatementStatus;
  created: string;
  updated: string;
  collectiveInvoice: { currency: Currency };
};

export type TransactionStatementDetailsFragmentFragment = {
  uuid: string;
  amount: string;
  collectiveInvoiceId?: string | null;
  identifier?: string | null;
  pdf?: string | null;
  transactionDate?: string | null;
  status: TransactionStatementStatus;
  created: string;
  updated: string;
  settlementSet: Array<{
    uuid: string;
    amount: string;
    description: string;
    created: string;
    currency: Currency;
    settlementType: SettlementType;
    status: SettlementStatus;
    invoice?: { uuid: string; identifier: string } | null;
  }>;
  collectiveInvoice: { currency: Currency };
};

export const StatementFragmentDoc = gql`
  fragment Statement on Statement {
    uuid
    identifier
    created
    issueDate
    dueDate
    invoiceType
    amount
    amountWithVat
    paid
    paidIn
    paymentTerm
    pdf
    status
    directPaymentTotal
    currency
  }
`;
export const FinqleMerchantContractOverviewFragmentDoc = gql`
  fragment FinqleMerchantContractOverview on FinqleMerchantContract {
    accountNumber
    accountName
    accountsPayableEmails
    accountsReceivableEmails
    agreements {
      id
      agreementType
      agreementUrl
      signedDate
    }
    bankAccountCode
    branch {
      id
      name
      branchNumber
      isMainBranch
      billingAddress {
        city
        countryCode
        floor
        houseNumber
        phone
        postalCode
        street
      }
    }
    contactName
    contactPhone
    contactMobile
    contactEmail
    defaultGsplitPercentage
    defaultFactorFees
    enabled
    gAccountNumber
    id
    invoicingEmails
    signedDate
    status
    ublToken
  }
`;
export const SettlementFragmentFragmentDoc = gql`
  fragment SettlementFragment on Settlement {
    uuid
    amount
    currency
    created
    description
    invoiceUuid
    invoice {
      uuid
      identifier
    }
    settledWithId
    settledWith {
      id
      officialName
    }
    transactionStatementId
    transactionStatement {
      uuid
      identifier
      transactionDate
    }
    settlementType
    status
  }
`;
export const CreditLimitFragmentFragmentDoc = gql`
  fragment CreditLimitFragment on CreditLimitType {
    creditLimit
    creditUsed
    creditAvailable
  }
`;
export const InvoiceFragmentFragmentDoc = gql`
  fragment InvoiceFragment on Invoice {
    amount
    amountWithVat
    attachments
    created
    currency
    daysOpen
    directPayment
    disapprovalReason
    factorFee
    gsplitPercentage
    gPart
    invoiceNumber
    invoiceType
    issueDate
    issuedByCompanyId
    issuedTo
    issuedToBranchId
    issuedToCompanyId
    issuedBy
    isUploaded
    paid
    paidIn
    paymentTerm
    pdf
    poNumber
    projectId
    projectName
    projectPoNumber
    reference
    settlementSet {
      amount
      created
      settledWithId
      settlementType
    }
    status
    transactionStatement
    transactionStatementUuid
    transferStatement
    transferStatementUuid
    updated
    uuid
    unitLineItems {
      uuid
      vat
      amount
      amountWithVat
      quantity
      tariff
      description
    }
    receivableTotal
    outstandingTotal
  }
`;
export const InvoicesOverviewFragmentFragmentDoc = gql`
  fragment InvoicesOverviewFragment on Invoice {
    amountWithVat
    created
    currency
    daysOpen
    invoiceNumber
    invoiceType
    issueDate
    issuedTo
    issuedToBranchId
    issuedToCompanyId
    isUploaded
    paid
    paidIn
    paymentTerm
    pdf
    status
    transactionStatement
    transactionStatementUuid
    uuid
    receivableTotal
  }
`;
export const VatRateFragmentFragmentDoc = gql`
  fragment VatRateFragment on VatRate {
    country
    level
    rate
    startDate
  }
`;
export const TransferStatementFragmentFragmentDoc = gql`
  fragment TransferStatementFragment on TransferStatement {
    uuid
    companyId
    collectiveInvoiceId
    collectiveInvoice {
      currency
    }
    identifier
    pdf
    created
    updated
    sentOn
  }
`;
export const TransactionStatementBaseFragmentFragmentDoc = gql`
  fragment TransactionStatementBaseFragment on TransactionStatement {
    uuid
    amount
    collectiveInvoiceId
    collectiveInvoice {
      currency
    }
    identifier
    pdf
    transactionDate
    status
    created
    updated
  }
`;
export const TransactionStatementDetailsFragmentFragmentDoc = gql`
  fragment TransactionStatementDetailsFragment on TransactionStatement {
    ...TransactionStatementBaseFragment
    settlementSet {
      uuid
      amount
      description
      created
      currency
      invoice {
        uuid
        identifier
      }
      settlementType
      status
    }
  }
`;
export const CreateDebtorDocument = gql`
  mutation createDebtor($payload: CreateDebtorInput!) {
    createDebtor(payload: $payload) {
      id
    }
  }
`;
export type CreateDebtorMutationFn = Apollo.MutationFunction<CreateDebtorMutation, CreateDebtorMutationVariables>;

/**
 * __useCreateDebtorMutation__
 *
 * To run a mutation, you first call `useCreateDebtorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDebtorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDebtorMutation, { data, loading, error }] = useCreateDebtorMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateDebtorMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDebtorMutation, CreateDebtorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDebtorMutation, CreateDebtorMutationVariables>(CreateDebtorDocument, options);
}
export type CreateDebtorMutationHookResult = ReturnType<typeof useCreateDebtorMutation>;
export type CreateDebtorMutationResult = Apollo.MutationResult<CreateDebtorMutation>;
export type CreateDebtorMutationOptions = Apollo.BaseMutationOptions<
  CreateDebtorMutation,
  CreateDebtorMutationVariables
>;
export const CompanySearchDocument = gql`
  query companySearch($search: SearchInput!) {
    companySearch(search: $search) {
      companySearchId
      name
      address
      registrationNumber
      tradingNames
    }
  }
`;

/**
 * __useCompanySearchQuery__
 *
 * To run a query within a React component, call `useCompanySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCompanySearchQuery(
  baseOptions: Apollo.QueryHookOptions<CompanySearchQuery, CompanySearchQueryVariables> &
    ({ variables: CompanySearchQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanySearchQuery, CompanySearchQueryVariables>(CompanySearchDocument, options);
}
export function useCompanySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanySearchQuery, CompanySearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanySearchQuery, CompanySearchQueryVariables>(CompanySearchDocument, options);
}
export function useCompanySearchSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompanySearchQuery, CompanySearchQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanySearchQuery, CompanySearchQueryVariables>(CompanySearchDocument, options);
}
export type CompanySearchQueryHookResult = ReturnType<typeof useCompanySearchQuery>;
export type CompanySearchLazyQueryHookResult = ReturnType<typeof useCompanySearchLazyQuery>;
export type CompanySearchSuspenseQueryHookResult = ReturnType<typeof useCompanySearchSuspenseQuery>;
export type CompanySearchQueryResult = Apollo.QueryResult<CompanySearchQuery, CompanySearchQueryVariables>;
export const DebtorDocument = gql`
  query debtor($id: UUID!) {
    debtor(debtorId: $id) {
      id
      created
      company {
        officialName
        registrationNumber
        branchSet {
          accountsPayableEmails
          invoicingEmails
          name
          isMainBranch
          enabled
          branchNumber
          accountName
          accountNumber
          bankAccountCode
        }
      }
    }
  }
`;

/**
 * __useDebtorQuery__
 *
 * To run a query within a React component, call `useDebtorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDebtorQuery(
  baseOptions: Apollo.QueryHookOptions<DebtorQuery, DebtorQueryVariables> &
    ({ variables: DebtorQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DebtorQuery, DebtorQueryVariables>(DebtorDocument, options);
}
export function useDebtorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DebtorQuery, DebtorQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DebtorQuery, DebtorQueryVariables>(DebtorDocument, options);
}
export function useDebtorSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DebtorQuery, DebtorQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DebtorQuery, DebtorQueryVariables>(DebtorDocument, options);
}
export type DebtorQueryHookResult = ReturnType<typeof useDebtorQuery>;
export type DebtorLazyQueryHookResult = ReturnType<typeof useDebtorLazyQuery>;
export type DebtorSuspenseQueryHookResult = ReturnType<typeof useDebtorSuspenseQuery>;
export type DebtorQueryResult = Apollo.QueryResult<DebtorQuery, DebtorQueryVariables>;
export const BranchListDocument = gql`
  query branchList(
    $pagination: OffsetPaginationInput
    $ordering: BranchesOrderByChoicesQueryOrdering
    $filters: BranchFiltersInput
  ) {
    branches(pagination: $pagination, order: $ordering, filters: $filters) {
      items {
        id
        name
        accountName
        accountNumber
        accountsPayableEmails
        bankAccountCode
        branchNumber
        company {
          legalForm
          registrationNumber
          vatNumber
          contactInfo {
            phone
            website
          }
        }
        contactEmail
        contactMobile
        contactName
        contactPhone
        contractPdf
        currency
        defaultDepotPercentage
        defaultGsplitPercentage
        enabled
        invoicingEmails
        isMainBranch
        paymentTerm
        status
        creditLimit {
          ...CreditLimitFragment
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        hasPrevPage
      }
    }
  }
  ${CreditLimitFragmentFragmentDoc}
`;

/**
 * __useBranchListQuery__
 *
 * To run a query within a React component, call `useBranchListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBranchListQuery(baseOptions?: Apollo.QueryHookOptions<BranchListQuery, BranchListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BranchListQuery, BranchListQueryVariables>(BranchListDocument, options);
}
export function useBranchListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BranchListQuery, BranchListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BranchListQuery, BranchListQueryVariables>(BranchListDocument, options);
}
export function useBranchListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BranchListQuery, BranchListQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BranchListQuery, BranchListQueryVariables>(BranchListDocument, options);
}
export type BranchListQueryHookResult = ReturnType<typeof useBranchListQuery>;
export type BranchListLazyQueryHookResult = ReturnType<typeof useBranchListLazyQuery>;
export type BranchListSuspenseQueryHookResult = ReturnType<typeof useBranchListSuspenseQuery>;
export type BranchListQueryResult = Apollo.QueryResult<BranchListQuery, BranchListQueryVariables>;
export const GetBranchDocument = gql`
  query getBranch($branchId: UUID!) {
    branch(branchId: $branchId) {
      id
      name
      accountName
      accountNumber
      accountsPayableEmails
      bankAccountCode
      branchNumber
      company {
        legalForm
        registrationNumber
        vatNumber
        contactInfo {
          phone
          website
        }
      }
      contactEmail
      contactMobile
      contactName
      contactPhone
      contractPdf
      creditLimit {
        ...CreditLimitFragment
      }
      defaultDepotPercentage
      defaultGsplitPercentage
      enabled
      invoicingEmails
      isMainBranch
      paymentTerm
      status
      billingAddress {
        street
        houseNumber
        city
        postalCode
        countryCode
        floor
        phone
      }
    }
  }
  ${CreditLimitFragmentFragmentDoc}
`;

/**
 * __useGetBranchQuery__
 *
 * To run a query within a React component, call `useGetBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useGetBranchQuery(
  baseOptions: Apollo.QueryHookOptions<GetBranchQuery, GetBranchQueryVariables> &
    ({ variables: GetBranchQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBranchQuery, GetBranchQueryVariables>(GetBranchDocument, options);
}
export function useGetBranchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBranchQuery, GetBranchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBranchQuery, GetBranchQueryVariables>(GetBranchDocument, options);
}
export function useGetBranchSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBranchQuery, GetBranchQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetBranchQuery, GetBranchQueryVariables>(GetBranchDocument, options);
}
export type GetBranchQueryHookResult = ReturnType<typeof useGetBranchQuery>;
export type GetBranchLazyQueryHookResult = ReturnType<typeof useGetBranchLazyQuery>;
export type GetBranchSuspenseQueryHookResult = ReturnType<typeof useGetBranchSuspenseQuery>;
export type GetBranchQueryResult = Apollo.QueryResult<GetBranchQuery, GetBranchQueryVariables>;
export const RetrieveApiKeyDocument = gql`
  mutation RetrieveApiKey {
    retrieveApiKey {
      apiKey
    }
  }
`;
export type RetrieveApiKeyMutationFn = Apollo.MutationFunction<RetrieveApiKeyMutation, RetrieveApiKeyMutationVariables>;

/**
 * __useRetrieveApiKeyMutation__
 *
 * To run a mutation, you first call `useRetrieveApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetrieveApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retrieveApiKeyMutation, { data, loading, error }] = useRetrieveApiKeyMutation({
 *   variables: {
 *   },
 * });
 */
export function useRetrieveApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<RetrieveApiKeyMutation, RetrieveApiKeyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RetrieveApiKeyMutation, RetrieveApiKeyMutationVariables>(RetrieveApiKeyDocument, options);
}
export type RetrieveApiKeyMutationHookResult = ReturnType<typeof useRetrieveApiKeyMutation>;
export type RetrieveApiKeyMutationResult = Apollo.MutationResult<RetrieveApiKeyMutation>;
export type RetrieveApiKeyMutationOptions = Apollo.BaseMutationOptions<
  RetrieveApiKeyMutation,
  RetrieveApiKeyMutationVariables
>;
export const SettlementsDocument = gql`
  query settlements(
    $filters: SettlementFilters
    $pagination: Pagination
    $ordering: SettlementOrderByChoicesQueryOrdering
  ) {
    settlements(payload: { filters: $filters, pagination: $pagination, ordering: $ordering }) {
      items {
        ...SettlementFragment
      }
      pageInfo {
        hasNextPage
        hasPrevPage
        totalCount
      }
    }
  }
  ${SettlementFragmentFragmentDoc}
`;

/**
 * __useSettlementsQuery__
 *
 * To run a query within a React component, call `useSettlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettlementsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useSettlementsQuery(
  baseOptions?: Apollo.QueryHookOptions<SettlementsQuery, SettlementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SettlementsQuery, SettlementsQueryVariables>(SettlementsDocument, options);
}
export function useSettlementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SettlementsQuery, SettlementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SettlementsQuery, SettlementsQueryVariables>(SettlementsDocument, options);
}
export function useSettlementsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SettlementsQuery, SettlementsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SettlementsQuery, SettlementsQueryVariables>(SettlementsDocument, options);
}
export type SettlementsQueryHookResult = ReturnType<typeof useSettlementsQuery>;
export type SettlementsLazyQueryHookResult = ReturnType<typeof useSettlementsLazyQuery>;
export type SettlementsSuspenseQueryHookResult = ReturnType<typeof useSettlementsSuspenseQuery>;
export type SettlementsQueryResult = Apollo.QueryResult<SettlementsQuery, SettlementsQueryVariables>;
export const CreateMerchantUserDocument = gql`
  mutation createMerchantUser($payload: MerchantUserInput!) {
    createMerchantUser(payload: $payload) {
      id
    }
  }
`;
export type CreateMerchantUserMutationFn = Apollo.MutationFunction<
  CreateMerchantUserMutation,
  CreateMerchantUserMutationVariables
>;

/**
 * __useCreateMerchantUserMutation__
 *
 * To run a mutation, you first call `useCreateMerchantUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMerchantUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMerchantUserMutation, { data, loading, error }] = useCreateMerchantUserMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateMerchantUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMerchantUserMutation, CreateMerchantUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMerchantUserMutation, CreateMerchantUserMutationVariables>(
    CreateMerchantUserDocument,
    options,
  );
}
export type CreateMerchantUserMutationHookResult = ReturnType<typeof useCreateMerchantUserMutation>;
export type CreateMerchantUserMutationResult = Apollo.MutationResult<CreateMerchantUserMutation>;
export type CreateMerchantUserMutationOptions = Apollo.BaseMutationOptions<
  CreateMerchantUserMutation,
  CreateMerchantUserMutationVariables
>;
export const MerchantUsersDocument = gql`
  query merchantUsers($pagination: Pagination!) {
    merchantUsers(pagination: $pagination) {
      items {
        id
        email
        firstName
        lastName
        mobile
        phone
        enabled
      }
      pageInfo {
        totalCount
        hasNextPage
        hasPrevPage
      }
    }
  }
`;

/**
 * __useMerchantUsersQuery__
 *
 * To run a query within a React component, call `useMerchantUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantUsersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useMerchantUsersQuery(
  baseOptions: Apollo.QueryHookOptions<MerchantUsersQuery, MerchantUsersQueryVariables> &
    ({ variables: MerchantUsersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MerchantUsersQuery, MerchantUsersQueryVariables>(MerchantUsersDocument, options);
}
export function useMerchantUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MerchantUsersQuery, MerchantUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MerchantUsersQuery, MerchantUsersQueryVariables>(MerchantUsersDocument, options);
}
export function useMerchantUsersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MerchantUsersQuery, MerchantUsersQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MerchantUsersQuery, MerchantUsersQueryVariables>(MerchantUsersDocument, options);
}
export type MerchantUsersQueryHookResult = ReturnType<typeof useMerchantUsersQuery>;
export type MerchantUsersLazyQueryHookResult = ReturnType<typeof useMerchantUsersLazyQuery>;
export type MerchantUsersSuspenseQueryHookResult = ReturnType<typeof useMerchantUsersSuspenseQuery>;
export type MerchantUsersQueryResult = Apollo.QueryResult<MerchantUsersQuery, MerchantUsersQueryVariables>;
export const MerchantUserDocument = gql`
  query merchantUser($uuid: UUID!) {
    merchantUser(uuid: $uuid) {
      id
      email
      firstName
      lastName
      mobile
      phone
      salutation
      enabled
      created
    }
  }
`;

/**
 * __useMerchantUserQuery__
 *
 * To run a query within a React component, call `useMerchantUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantUserQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMerchantUserQuery(
  baseOptions: Apollo.QueryHookOptions<MerchantUserQuery, MerchantUserQueryVariables> &
    ({ variables: MerchantUserQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MerchantUserQuery, MerchantUserQueryVariables>(MerchantUserDocument, options);
}
export function useMerchantUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MerchantUserQuery, MerchantUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MerchantUserQuery, MerchantUserQueryVariables>(MerchantUserDocument, options);
}
export function useMerchantUserSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MerchantUserQuery, MerchantUserQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MerchantUserQuery, MerchantUserQueryVariables>(MerchantUserDocument, options);
}
export type MerchantUserQueryHookResult = ReturnType<typeof useMerchantUserQuery>;
export type MerchantUserLazyQueryHookResult = ReturnType<typeof useMerchantUserLazyQuery>;
export type MerchantUserSuspenseQueryHookResult = ReturnType<typeof useMerchantUserSuspenseQuery>;
export type MerchantUserQueryResult = Apollo.QueryResult<MerchantUserQuery, MerchantUserQueryVariables>;
export const UploadInvoiceDocument = gql`
  mutation uploadInvoice(
    $debtorBranchId: UUID!
    $amount: Decimal!
    $amountWithVat: Decimal!
    $directPaymentAmount: Decimal!
    $attachments: [Upload!]!
    $pdf: Upload!
    $poNumber: String!
    $issueDate: Date!
    $identifier: String!
    $reference: String
    $projectName: String
  ) {
    uploadInvoice(
      data: {
        debtorBranchId: $debtorBranchId
        amount: $amount
        amountWithVat: $amountWithVat
        directPaymentAmount: $directPaymentAmount
        attachments: $attachments
        pdf: $pdf
        poNumber: $poNumber
        issueDate: $issueDate
        identifier: $identifier
        reference: $reference
        projectName: $projectName
      }
    ) {
      uuid
    }
  }
`;
export type UploadInvoiceMutationFn = Apollo.MutationFunction<UploadInvoiceMutation, UploadInvoiceMutationVariables>;

/**
 * __useUploadInvoiceMutation__
 *
 * To run a mutation, you first call `useUploadInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadInvoiceMutation, { data, loading, error }] = useUploadInvoiceMutation({
 *   variables: {
 *      debtorBranchId: // value for 'debtorBranchId'
 *      amount: // value for 'amount'
 *      amountWithVat: // value for 'amountWithVat'
 *      directPaymentAmount: // value for 'directPaymentAmount'
 *      attachments: // value for 'attachments'
 *      pdf: // value for 'pdf'
 *      poNumber: // value for 'poNumber'
 *      issueDate: // value for 'issueDate'
 *      identifier: // value for 'identifier'
 *      reference: // value for 'reference'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useUploadInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadInvoiceMutation, UploadInvoiceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadInvoiceMutation, UploadInvoiceMutationVariables>(UploadInvoiceDocument, options);
}
export type UploadInvoiceMutationHookResult = ReturnType<typeof useUploadInvoiceMutation>;
export type UploadInvoiceMutationResult = Apollo.MutationResult<UploadInvoiceMutation>;
export type UploadInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UploadInvoiceMutation,
  UploadInvoiceMutationVariables
>;
export const UpdateInvoiceDocument = gql`
  mutation updateInvoice(
    $uuid: UUID!
    $debtorBranchId: UUID!
    $amount: Decimal!
    $amountWithVat: Decimal!
    $directPaymentAmount: Decimal!
    $attachments: [Upload!]
    $pdf: Upload
    $poNumber: String!
    $issueDate: Date!
    $identifier: String!
    $reference: String
    $projectName: String
  ) {
    updateInvoice(
      data: {
        uuid: $uuid
        debtorBranchId: $debtorBranchId
        amount: $amount
        amountWithVat: $amountWithVat
        directPaymentAmount: $directPaymentAmount
        attachments: $attachments
        pdf: $pdf
        poNumber: $poNumber
        issueDate: $issueDate
        identifier: $identifier
        reference: $reference
        projectName: $projectName
      }
    ) {
      uuid
    }
  }
`;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      debtorBranchId: // value for 'debtorBranchId'
 *      amount: // value for 'amount'
 *      amountWithVat: // value for 'amountWithVat'
 *      directPaymentAmount: // value for 'directPaymentAmount'
 *      attachments: // value for 'attachments'
 *      pdf: // value for 'pdf'
 *      poNumber: // value for 'poNumber'
 *      issueDate: // value for 'issueDate'
 *      identifier: // value for 'identifier'
 *      reference: // value for 'reference'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
}
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables
>;
export const SubmitInvoiceDocument = gql`
  mutation submitInvoice($uuid: UUID!) {
    submitInvoice(uuid: $uuid) {
      success
    }
  }
`;
export type SubmitInvoiceMutationFn = Apollo.MutationFunction<SubmitInvoiceMutation, SubmitInvoiceMutationVariables>;

/**
 * __useSubmitInvoiceMutation__
 *
 * To run a mutation, you first call `useSubmitInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitInvoiceMutation, { data, loading, error }] = useSubmitInvoiceMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSubmitInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitInvoiceMutation, SubmitInvoiceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitInvoiceMutation, SubmitInvoiceMutationVariables>(SubmitInvoiceDocument, options);
}
export type SubmitInvoiceMutationHookResult = ReturnType<typeof useSubmitInvoiceMutation>;
export type SubmitInvoiceMutationResult = Apollo.MutationResult<SubmitInvoiceMutation>;
export type SubmitInvoiceMutationOptions = Apollo.BaseMutationOptions<
  SubmitInvoiceMutation,
  SubmitInvoiceMutationVariables
>;
export const ReopenDisapprovedInvoiceDocument = gql`
  mutation reopenDisapprovedInvoice($uuid: UUID!) {
    reopenDisapprovedInvoice(uuid: $uuid) {
      success
    }
  }
`;
export type ReopenDisapprovedInvoiceMutationFn = Apollo.MutationFunction<
  ReopenDisapprovedInvoiceMutation,
  ReopenDisapprovedInvoiceMutationVariables
>;

/**
 * __useReopenDisapprovedInvoiceMutation__
 *
 * To run a mutation, you first call `useReopenDisapprovedInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenDisapprovedInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenDisapprovedInvoiceMutation, { data, loading, error }] = useReopenDisapprovedInvoiceMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useReopenDisapprovedInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<ReopenDisapprovedInvoiceMutation, ReopenDisapprovedInvoiceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReopenDisapprovedInvoiceMutation, ReopenDisapprovedInvoiceMutationVariables>(
    ReopenDisapprovedInvoiceDocument,
    options,
  );
}
export type ReopenDisapprovedInvoiceMutationHookResult = ReturnType<typeof useReopenDisapprovedInvoiceMutation>;
export type ReopenDisapprovedInvoiceMutationResult = Apollo.MutationResult<ReopenDisapprovedInvoiceMutation>;
export type ReopenDisapprovedInvoiceMutationOptions = Apollo.BaseMutationOptions<
  ReopenDisapprovedInvoiceMutation,
  ReopenDisapprovedInvoiceMutationVariables
>;
export const DeleteInvoiceDocument = gql`
  mutation deleteInvoice($uuid: UUID!) {
    deleteInvoice(uuid: $uuid) {
      success
    }
  }
`;
export type DeleteInvoiceMutationFn = Apollo.MutationFunction<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;

/**
 * __useDeleteInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceMutation, { data, loading, error }] = useDeleteInvoiceMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>(DeleteInvoiceDocument, options);
}
export type DeleteInvoiceMutationHookResult = ReturnType<typeof useDeleteInvoiceMutation>;
export type DeleteInvoiceMutationResult = Apollo.MutationResult<DeleteInvoiceMutation>;
export type DeleteInvoiceMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvoiceMutation,
  DeleteInvoiceMutationVariables
>;
export const CreateInvoiceDocument = gql`
  mutation createInvoice($data: CreateInvoiceInput!) {
    createInvoice(data: $data) {
      uuid
    }
  }
`;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, options);
}
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables
>;
export const UpdateCreatedInvoiceDocument = gql`
  mutation updateCreatedInvoice($data: UpdateCreatedInvoiceInput!) {
    updateCreatedInvoice(data: $data) {
      uuid
    }
  }
`;
export type UpdateCreatedInvoiceMutationFn = Apollo.MutationFunction<
  UpdateCreatedInvoiceMutation,
  UpdateCreatedInvoiceMutationVariables
>;

/**
 * __useUpdateCreatedInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateCreatedInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreatedInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreatedInvoiceMutation, { data, loading, error }] = useUpdateCreatedInvoiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCreatedInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCreatedInvoiceMutation, UpdateCreatedInvoiceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCreatedInvoiceMutation, UpdateCreatedInvoiceMutationVariables>(
    UpdateCreatedInvoiceDocument,
    options,
  );
}
export type UpdateCreatedInvoiceMutationHookResult = ReturnType<typeof useUpdateCreatedInvoiceMutation>;
export type UpdateCreatedInvoiceMutationResult = Apollo.MutationResult<UpdateCreatedInvoiceMutation>;
export type UpdateCreatedInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateCreatedInvoiceMutation,
  UpdateCreatedInvoiceMutationVariables
>;
export const BranchesAutocompleteListDocument = gql`
  query BranchesAutocompleteList(
    $pagination: OffsetPaginationInput
    $ordering: BranchesOrderByChoicesQueryOrdering
    $filters: BranchFiltersInput
  ) {
    branches(pagination: $pagination, order: $ordering, filters: $filters) {
      items {
        id
        name
      }
    }
  }
`;

/**
 * __useBranchesAutocompleteListQuery__
 *
 * To run a query within a React component, call `useBranchesAutocompleteListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesAutocompleteListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchesAutocompleteListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBranchesAutocompleteListQuery(
  baseOptions?: Apollo.QueryHookOptions<BranchesAutocompleteListQuery, BranchesAutocompleteListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BranchesAutocompleteListQuery, BranchesAutocompleteListQueryVariables>(
    BranchesAutocompleteListDocument,
    options,
  );
}
export function useBranchesAutocompleteListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BranchesAutocompleteListQuery, BranchesAutocompleteListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BranchesAutocompleteListQuery, BranchesAutocompleteListQueryVariables>(
    BranchesAutocompleteListDocument,
    options,
  );
}
export function useBranchesAutocompleteListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BranchesAutocompleteListQuery, BranchesAutocompleteListQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BranchesAutocompleteListQuery, BranchesAutocompleteListQueryVariables>(
    BranchesAutocompleteListDocument,
    options,
  );
}
export type BranchesAutocompleteListQueryHookResult = ReturnType<typeof useBranchesAutocompleteListQuery>;
export type BranchesAutocompleteListLazyQueryHookResult = ReturnType<typeof useBranchesAutocompleteListLazyQuery>;
export type BranchesAutocompleteListSuspenseQueryHookResult = ReturnType<
  typeof useBranchesAutocompleteListSuspenseQuery
>;
export type BranchesAutocompleteListQueryResult = Apollo.QueryResult<
  BranchesAutocompleteListQuery,
  BranchesAutocompleteListQueryVariables
>;
export const BranchForInvoiceEditDocument = gql`
  query BranchForInvoiceEdit($branchId: UUID!) {
    branch(branchId: $branchId) {
      currency
      defaultDepotPercentage
      defaultGsplitPercentage
      id
      name
      paymentTerm
    }
  }
`;

/**
 * __useBranchForInvoiceEditQuery__
 *
 * To run a query within a React component, call `useBranchForInvoiceEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchForInvoiceEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchForInvoiceEditQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useBranchForInvoiceEditQuery(
  baseOptions: Apollo.QueryHookOptions<BranchForInvoiceEditQuery, BranchForInvoiceEditQueryVariables> &
    ({ variables: BranchForInvoiceEditQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BranchForInvoiceEditQuery, BranchForInvoiceEditQueryVariables>(
    BranchForInvoiceEditDocument,
    options,
  );
}
export function useBranchForInvoiceEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BranchForInvoiceEditQuery, BranchForInvoiceEditQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BranchForInvoiceEditQuery, BranchForInvoiceEditQueryVariables>(
    BranchForInvoiceEditDocument,
    options,
  );
}
export function useBranchForInvoiceEditSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BranchForInvoiceEditQuery, BranchForInvoiceEditQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BranchForInvoiceEditQuery, BranchForInvoiceEditQueryVariables>(
    BranchForInvoiceEditDocument,
    options,
  );
}
export type BranchForInvoiceEditQueryHookResult = ReturnType<typeof useBranchForInvoiceEditQuery>;
export type BranchForInvoiceEditLazyQueryHookResult = ReturnType<typeof useBranchForInvoiceEditLazyQuery>;
export type BranchForInvoiceEditSuspenseQueryHookResult = ReturnType<typeof useBranchForInvoiceEditSuspenseQuery>;
export type BranchForInvoiceEditQueryResult = Apollo.QueryResult<
  BranchForInvoiceEditQuery,
  BranchForInvoiceEditQueryVariables
>;
export const BranchForInvoiceFilterDocument = gql`
  query BranchForInvoiceFilter($branchId: UUID!) {
    branch(branchId: $branchId) {
      name
    }
  }
`;

/**
 * __useBranchForInvoiceFilterQuery__
 *
 * To run a query within a React component, call `useBranchForInvoiceFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchForInvoiceFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchForInvoiceFilterQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useBranchForInvoiceFilterQuery(
  baseOptions: Apollo.QueryHookOptions<BranchForInvoiceFilterQuery, BranchForInvoiceFilterQueryVariables> &
    ({ variables: BranchForInvoiceFilterQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BranchForInvoiceFilterQuery, BranchForInvoiceFilterQueryVariables>(
    BranchForInvoiceFilterDocument,
    options,
  );
}
export function useBranchForInvoiceFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BranchForInvoiceFilterQuery, BranchForInvoiceFilterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BranchForInvoiceFilterQuery, BranchForInvoiceFilterQueryVariables>(
    BranchForInvoiceFilterDocument,
    options,
  );
}
export function useBranchForInvoiceFilterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BranchForInvoiceFilterQuery, BranchForInvoiceFilterQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BranchForInvoiceFilterQuery, BranchForInvoiceFilterQueryVariables>(
    BranchForInvoiceFilterDocument,
    options,
  );
}
export type BranchForInvoiceFilterQueryHookResult = ReturnType<typeof useBranchForInvoiceFilterQuery>;
export type BranchForInvoiceFilterLazyQueryHookResult = ReturnType<typeof useBranchForInvoiceFilterLazyQuery>;
export type BranchForInvoiceFilterSuspenseQueryHookResult = ReturnType<typeof useBranchForInvoiceFilterSuspenseQuery>;
export type BranchForInvoiceFilterQueryResult = Apollo.QueryResult<
  BranchForInvoiceFilterQuery,
  BranchForInvoiceFilterQueryVariables
>;
export const InvoicesDocument = gql`
  query invoices($filters: InvoiceFilters!, $pagination: Pagination, $ordering: InvoicesOrderByChoicesQueryOrdering) {
    invoices(payload: { filters: $filters, pagination: $pagination, ordering: $ordering }) {
      items {
        ...InvoicesOverviewFragment
      }
      pageInfo {
        hasPrevPage
        hasNextPage
        totalCount
      }
    }
  }
  ${InvoicesOverviewFragmentFragmentDoc}
`;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useInvoicesQuery(
  baseOptions: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables> &
    ({ variables: InvoicesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
}
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
}
export function useInvoicesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
}
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesSuspenseQueryHookResult = ReturnType<typeof useInvoicesSuspenseQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const InvoiceDocument = gql`
  query invoice($uuid: UUID!) {
    invoice(uuid: $uuid) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables> &
    ({ variables: InvoiceQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
}
export function useInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
}
export function useInvoiceSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
}
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceSuspenseQueryHookResult = ReturnType<typeof useInvoiceSuspenseQuery>;
export type InvoiceQueryResult = Apollo.QueryResult<InvoiceQuery, InvoiceQueryVariables>;
export const MerchantDocument = gql`
  query merchant {
    merchant {
      id
      company {
        id
        officialName
      }
      contract {
        currency
        branch {
          billingAddress {
            countryCode
          }
        }
      }
    }
  }
`;

/**
 * __useMerchantQuery__
 *
 * To run a query within a React component, call `useMerchantQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantQuery({
 *   variables: {
 *   },
 * });
 */
export function useMerchantQuery(baseOptions?: Apollo.QueryHookOptions<MerchantQuery, MerchantQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MerchantQuery, MerchantQueryVariables>(MerchantDocument, options);
}
export function useMerchantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantQuery, MerchantQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MerchantQuery, MerchantQueryVariables>(MerchantDocument, options);
}
export function useMerchantSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MerchantQuery, MerchantQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MerchantQuery, MerchantQueryVariables>(MerchantDocument, options);
}
export type MerchantQueryHookResult = ReturnType<typeof useMerchantQuery>;
export type MerchantLazyQueryHookResult = ReturnType<typeof useMerchantLazyQuery>;
export type MerchantSuspenseQueryHookResult = ReturnType<typeof useMerchantSuspenseQuery>;
export type MerchantQueryResult = Apollo.QueryResult<MerchantQuery, MerchantQueryVariables>;
export const InvoicesAutocompleteListDocument = gql`
  query InvoicesAutocompleteList($filters: InvoiceFilters!) {
    invoices(payload: { filters: $filters }) {
      items {
        uuid
        invoiceNumber
      }
    }
  }
`;

/**
 * __useInvoicesAutocompleteListQuery__
 *
 * To run a query within a React component, call `useInvoicesAutocompleteListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesAutocompleteListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesAutocompleteListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useInvoicesAutocompleteListQuery(
  baseOptions: Apollo.QueryHookOptions<InvoicesAutocompleteListQuery, InvoicesAutocompleteListQueryVariables> &
    ({ variables: InvoicesAutocompleteListQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoicesAutocompleteListQuery, InvoicesAutocompleteListQueryVariables>(
    InvoicesAutocompleteListDocument,
    options,
  );
}
export function useInvoicesAutocompleteListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvoicesAutocompleteListQuery, InvoicesAutocompleteListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoicesAutocompleteListQuery, InvoicesAutocompleteListQueryVariables>(
    InvoicesAutocompleteListDocument,
    options,
  );
}
export function useInvoicesAutocompleteListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<InvoicesAutocompleteListQuery, InvoicesAutocompleteListQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvoicesAutocompleteListQuery, InvoicesAutocompleteListQueryVariables>(
    InvoicesAutocompleteListDocument,
    options,
  );
}
export type InvoicesAutocompleteListQueryHookResult = ReturnType<typeof useInvoicesAutocompleteListQuery>;
export type InvoicesAutocompleteListLazyQueryHookResult = ReturnType<typeof useInvoicesAutocompleteListLazyQuery>;
export type InvoicesAutocompleteListSuspenseQueryHookResult = ReturnType<
  typeof useInvoicesAutocompleteListSuspenseQuery
>;
export type InvoicesAutocompleteListQueryResult = Apollo.QueryResult<
  InvoicesAutocompleteListQuery,
  InvoicesAutocompleteListQueryVariables
>;
export const InvoiceForFiltersDocument = gql`
  query InvoiceForFilters($uuid: UUID!) {
    invoice(uuid: $uuid) {
      uuid
      invoiceNumber
    }
  }
`;

/**
 * __useInvoiceForFiltersQuery__
 *
 * To run a query within a React component, call `useInvoiceForFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceForFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceForFiltersQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useInvoiceForFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<InvoiceForFiltersQuery, InvoiceForFiltersQueryVariables> &
    ({ variables: InvoiceForFiltersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoiceForFiltersQuery, InvoiceForFiltersQueryVariables>(InvoiceForFiltersDocument, options);
}
export function useInvoiceForFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvoiceForFiltersQuery, InvoiceForFiltersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoiceForFiltersQuery, InvoiceForFiltersQueryVariables>(
    InvoiceForFiltersDocument,
    options,
  );
}
export function useInvoiceForFiltersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<InvoiceForFiltersQuery, InvoiceForFiltersQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvoiceForFiltersQuery, InvoiceForFiltersQueryVariables>(
    InvoiceForFiltersDocument,
    options,
  );
}
export type InvoiceForFiltersQueryHookResult = ReturnType<typeof useInvoiceForFiltersQuery>;
export type InvoiceForFiltersLazyQueryHookResult = ReturnType<typeof useInvoiceForFiltersLazyQuery>;
export type InvoiceForFiltersSuspenseQueryHookResult = ReturnType<typeof useInvoiceForFiltersSuspenseQuery>;
export type InvoiceForFiltersQueryResult = Apollo.QueryResult<InvoiceForFiltersQuery, InvoiceForFiltersQueryVariables>;
export const UpdateUserLocaleDocument = gql`
  mutation updateUserLocale($locale: String!) {
    updateUserLocale(locale: $locale) {
      success
    }
  }
`;
export type UpdateUserLocaleMutationFn = Apollo.MutationFunction<
  UpdateUserLocaleMutation,
  UpdateUserLocaleMutationVariables
>;

/**
 * __useUpdateUserLocaleMutation__
 *
 * To run a mutation, you first call `useUpdateUserLocaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLocaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLocaleMutation, { data, loading, error }] = useUpdateUserLocaleMutation({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useUpdateUserLocaleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserLocaleMutation, UpdateUserLocaleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserLocaleMutation, UpdateUserLocaleMutationVariables>(
    UpdateUserLocaleDocument,
    options,
  );
}
export type UpdateUserLocaleMutationHookResult = ReturnType<typeof useUpdateUserLocaleMutation>;
export type UpdateUserLocaleMutationResult = Apollo.MutationResult<UpdateUserLocaleMutation>;
export type UpdateUserLocaleMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserLocaleMutation,
  UpdateUserLocaleMutationVariables
>;
export const GetCompanyDetailsDocument = gql`
  query getCompanyDetails {
    merchant {
      id
      enabled
      created
      updated
      company {
        id
        officialName
        tradeName
        registrationNumber
        vatNumber
        legalForm
      }
      contract {
        ...FinqleMerchantContractOverview
      }
      logo
    }
  }
  ${FinqleMerchantContractOverviewFragmentDoc}
`;

/**
 * __useGetCompanyDetailsQuery__
 *
 * To run a query within a React component, call `useGetCompanyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompanyDetailsQuery, GetCompanyDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyDetailsQuery, GetCompanyDetailsQueryVariables>(GetCompanyDetailsDocument, options);
}
export function useGetCompanyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyDetailsQuery, GetCompanyDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyDetailsQuery, GetCompanyDetailsQueryVariables>(
    GetCompanyDetailsDocument,
    options,
  );
}
export function useGetCompanyDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCompanyDetailsQuery, GetCompanyDetailsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCompanyDetailsQuery, GetCompanyDetailsQueryVariables>(
    GetCompanyDetailsDocument,
    options,
  );
}
export type GetCompanyDetailsQueryHookResult = ReturnType<typeof useGetCompanyDetailsQuery>;
export type GetCompanyDetailsLazyQueryHookResult = ReturnType<typeof useGetCompanyDetailsLazyQuery>;
export type GetCompanyDetailsSuspenseQueryHookResult = ReturnType<typeof useGetCompanyDetailsSuspenseQuery>;
export type GetCompanyDetailsQueryResult = Apollo.QueryResult<GetCompanyDetailsQuery, GetCompanyDetailsQueryVariables>;
export const GetMerchantCompanyNameDocument = gql`
  query getMerchantCompanyName {
    merchant {
      company {
        officialName
      }
    }
  }
`;

/**
 * __useGetMerchantCompanyNameQuery__
 *
 * To run a query within a React component, call `useGetMerchantCompanyNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMerchantCompanyNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMerchantCompanyNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMerchantCompanyNameQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMerchantCompanyNameQuery, GetMerchantCompanyNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMerchantCompanyNameQuery, GetMerchantCompanyNameQueryVariables>(
    GetMerchantCompanyNameDocument,
    options,
  );
}
export function useGetMerchantCompanyNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMerchantCompanyNameQuery, GetMerchantCompanyNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMerchantCompanyNameQuery, GetMerchantCompanyNameQueryVariables>(
    GetMerchantCompanyNameDocument,
    options,
  );
}
export function useGetMerchantCompanyNameSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetMerchantCompanyNameQuery, GetMerchantCompanyNameQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMerchantCompanyNameQuery, GetMerchantCompanyNameQueryVariables>(
    GetMerchantCompanyNameDocument,
    options,
  );
}
export type GetMerchantCompanyNameQueryHookResult = ReturnType<typeof useGetMerchantCompanyNameQuery>;
export type GetMerchantCompanyNameLazyQueryHookResult = ReturnType<typeof useGetMerchantCompanyNameLazyQuery>;
export type GetMerchantCompanyNameSuspenseQueryHookResult = ReturnType<typeof useGetMerchantCompanyNameSuspenseQuery>;
export type GetMerchantCompanyNameQueryResult = Apollo.QueryResult<
  GetMerchantCompanyNameQuery,
  GetMerchantCompanyNameQueryVariables
>;
export const StatementsDocument = gql`
  query statements($input: StatementsArgs!) {
    statements(input: $input) {
      data {
        ...Statement
      }
      pageInfo {
        hasPrevPage
        hasNextPage
        totalCount
      }
    }
  }
  ${StatementFragmentDoc}
`;

/**
 * __useStatementsQuery__
 *
 * To run a query within a React component, call `useStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStatementsQuery(
  baseOptions: Apollo.QueryHookOptions<StatementsQuery, StatementsQueryVariables> &
    ({ variables: StatementsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StatementsQuery, StatementsQueryVariables>(StatementsDocument, options);
}
export function useStatementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StatementsQuery, StatementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StatementsQuery, StatementsQueryVariables>(StatementsDocument, options);
}
export function useStatementsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StatementsQuery, StatementsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StatementsQuery, StatementsQueryVariables>(StatementsDocument, options);
}
export type StatementsQueryHookResult = ReturnType<typeof useStatementsQuery>;
export type StatementsLazyQueryHookResult = ReturnType<typeof useStatementsLazyQuery>;
export type StatementsSuspenseQueryHookResult = ReturnType<typeof useStatementsSuspenseQuery>;
export type StatementsQueryResult = Apollo.QueryResult<StatementsQuery, StatementsQueryVariables>;
export const StatementDocument = gql`
  query statement($uuid: UUID!) {
    statement(uuid: $uuid) {
      ...Statement
    }
  }
  ${StatementFragmentDoc}
`;

/**
 * __useStatementQuery__
 *
 * To run a query within a React component, call `useStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatementQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useStatementQuery(
  baseOptions: Apollo.QueryHookOptions<StatementQuery, StatementQueryVariables> &
    ({ variables: StatementQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StatementQuery, StatementQueryVariables>(StatementDocument, options);
}
export function useStatementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StatementQuery, StatementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StatementQuery, StatementQueryVariables>(StatementDocument, options);
}
export function useStatementSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StatementQuery, StatementQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StatementQuery, StatementQueryVariables>(StatementDocument, options);
}
export type StatementQueryHookResult = ReturnType<typeof useStatementQuery>;
export type StatementLazyQueryHookResult = ReturnType<typeof useStatementLazyQuery>;
export type StatementSuspenseQueryHookResult = ReturnType<typeof useStatementSuspenseQuery>;
export type StatementQueryResult = Apollo.QueryResult<StatementQuery, StatementQueryVariables>;
export const TransactionStatementsDocument = gql`
  query TransactionStatements($payload: TransactionStatementsArgs!) {
    transactionStatements(payload: $payload) {
      items {
        ...TransactionStatementBaseFragment
      }
      pageInfo {
        hasPrevPage
        hasNextPage
        totalCount
      }
    }
  }
  ${TransactionStatementBaseFragmentFragmentDoc}
`;

/**
 * __useTransactionStatementsQuery__
 *
 * To run a query within a React component, call `useTransactionStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionStatementsQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useTransactionStatementsQuery(
  baseOptions: Apollo.QueryHookOptions<TransactionStatementsQuery, TransactionStatementsQueryVariables> &
    ({ variables: TransactionStatementsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransactionStatementsQuery, TransactionStatementsQueryVariables>(
    TransactionStatementsDocument,
    options,
  );
}
export function useTransactionStatementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TransactionStatementsQuery, TransactionStatementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransactionStatementsQuery, TransactionStatementsQueryVariables>(
    TransactionStatementsDocument,
    options,
  );
}
export function useTransactionStatementsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TransactionStatementsQuery, TransactionStatementsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TransactionStatementsQuery, TransactionStatementsQueryVariables>(
    TransactionStatementsDocument,
    options,
  );
}
export type TransactionStatementsQueryHookResult = ReturnType<typeof useTransactionStatementsQuery>;
export type TransactionStatementsLazyQueryHookResult = ReturnType<typeof useTransactionStatementsLazyQuery>;
export type TransactionStatementsSuspenseQueryHookResult = ReturnType<typeof useTransactionStatementsSuspenseQuery>;
export type TransactionStatementsQueryResult = Apollo.QueryResult<
  TransactionStatementsQuery,
  TransactionStatementsQueryVariables
>;
export const TransactionStatementDocument = gql`
  query TransactionStatement($uuid: UUID!) {
    transactionStatement(uuid: $uuid) {
      ...TransactionStatementDetailsFragment
    }
  }
  ${TransactionStatementDetailsFragmentFragmentDoc}
  ${TransactionStatementBaseFragmentFragmentDoc}
`;

/**
 * __useTransactionStatementQuery__
 *
 * To run a query within a React component, call `useTransactionStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionStatementQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useTransactionStatementQuery(
  baseOptions: Apollo.QueryHookOptions<TransactionStatementQuery, TransactionStatementQueryVariables> &
    ({ variables: TransactionStatementQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransactionStatementQuery, TransactionStatementQueryVariables>(
    TransactionStatementDocument,
    options,
  );
}
export function useTransactionStatementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TransactionStatementQuery, TransactionStatementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransactionStatementQuery, TransactionStatementQueryVariables>(
    TransactionStatementDocument,
    options,
  );
}
export function useTransactionStatementSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TransactionStatementQuery, TransactionStatementQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TransactionStatementQuery, TransactionStatementQueryVariables>(
    TransactionStatementDocument,
    options,
  );
}
export type TransactionStatementQueryHookResult = ReturnType<typeof useTransactionStatementQuery>;
export type TransactionStatementLazyQueryHookResult = ReturnType<typeof useTransactionStatementLazyQuery>;
export type TransactionStatementSuspenseQueryHookResult = ReturnType<typeof useTransactionStatementSuspenseQuery>;
export type TransactionStatementQueryResult = Apollo.QueryResult<
  TransactionStatementQuery,
  TransactionStatementQueryVariables
>;
export const TransferStatementsDocument = gql`
  query TransferStatements($payload: TransferStatementsArgs!) {
    transferStatements(payload: $payload) {
      items {
        ...TransferStatementFragment
      }
      pageInfo {
        hasPrevPage
        hasNextPage
        totalCount
      }
    }
  }
  ${TransferStatementFragmentFragmentDoc}
`;

/**
 * __useTransferStatementsQuery__
 *
 * To run a query within a React component, call `useTransferStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferStatementsQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useTransferStatementsQuery(
  baseOptions: Apollo.QueryHookOptions<TransferStatementsQuery, TransferStatementsQueryVariables> &
    ({ variables: TransferStatementsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransferStatementsQuery, TransferStatementsQueryVariables>(
    TransferStatementsDocument,
    options,
  );
}
export function useTransferStatementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TransferStatementsQuery, TransferStatementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransferStatementsQuery, TransferStatementsQueryVariables>(
    TransferStatementsDocument,
    options,
  );
}
export function useTransferStatementsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TransferStatementsQuery, TransferStatementsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TransferStatementsQuery, TransferStatementsQueryVariables>(
    TransferStatementsDocument,
    options,
  );
}
export type TransferStatementsQueryHookResult = ReturnType<typeof useTransferStatementsQuery>;
export type TransferStatementsLazyQueryHookResult = ReturnType<typeof useTransferStatementsLazyQuery>;
export type TransferStatementsSuspenseQueryHookResult = ReturnType<typeof useTransferStatementsSuspenseQuery>;
export type TransferStatementsQueryResult = Apollo.QueryResult<
  TransferStatementsQuery,
  TransferStatementsQueryVariables
>;
export const TransferStatementDocument = gql`
  query TransferStatement($uuid: UUID!) {
    transferStatement(uuid: $uuid) {
      ...TransferStatementFragment
    }
  }
  ${TransferStatementFragmentFragmentDoc}
`;

/**
 * __useTransferStatementQuery__
 *
 * To run a query within a React component, call `useTransferStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferStatementQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useTransferStatementQuery(
  baseOptions: Apollo.QueryHookOptions<TransferStatementQuery, TransferStatementQueryVariables> &
    ({ variables: TransferStatementQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransferStatementQuery, TransferStatementQueryVariables>(TransferStatementDocument, options);
}
export function useTransferStatementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TransferStatementQuery, TransferStatementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransferStatementQuery, TransferStatementQueryVariables>(
    TransferStatementDocument,
    options,
  );
}
export function useTransferStatementSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TransferStatementQuery, TransferStatementQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TransferStatementQuery, TransferStatementQueryVariables>(
    TransferStatementDocument,
    options,
  );
}
export type TransferStatementQueryHookResult = ReturnType<typeof useTransferStatementQuery>;
export type TransferStatementLazyQueryHookResult = ReturnType<typeof useTransferStatementLazyQuery>;
export type TransferStatementSuspenseQueryHookResult = ReturnType<typeof useTransferStatementSuspenseQuery>;
export type TransferStatementQueryResult = Apollo.QueryResult<TransferStatementQuery, TransferStatementQueryVariables>;
export const VatRatesDocument = gql`
  query vatRates($filters: VatRateFilters) {
    vatRates(filters: $filters) {
      country
      level
      rate
      startDate
    }
  }
`;

/**
 * __useVatRatesQuery__
 *
 * To run a query within a React component, call `useVatRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVatRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVatRatesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVatRatesQuery(baseOptions?: Apollo.QueryHookOptions<VatRatesQuery, VatRatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VatRatesQuery, VatRatesQueryVariables>(VatRatesDocument, options);
}
export function useVatRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VatRatesQuery, VatRatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VatRatesQuery, VatRatesQueryVariables>(VatRatesDocument, options);
}
export function useVatRatesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VatRatesQuery, VatRatesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VatRatesQuery, VatRatesQueryVariables>(VatRatesDocument, options);
}
export type VatRatesQueryHookResult = ReturnType<typeof useVatRatesQuery>;
export type VatRatesLazyQueryHookResult = ReturnType<typeof useVatRatesLazyQuery>;
export type VatRatesSuspenseQueryHookResult = ReturnType<typeof useVatRatesSuspenseQuery>;
export type VatRatesQueryResult = Apollo.QueryResult<VatRatesQuery, VatRatesQueryVariables>;
